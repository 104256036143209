import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { FaSignature, FaTerminal, FaBook, FaDiceD20, FaFilePdf } from 'react-icons/fa';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const IconSet = {
    'fa-signature': FaSignature,
    'fa-terminal': FaTerminal,
    'fa-book': FaBook,
    'fa-dice-d20': FaDiceD20,
    'fa-file-pdf': FaFilePdf
};


const CategoryItem = ({ category, node }) => {
    const Icon = IconSet[node.frontmatter.icon];
    return (
        <article className={"media " + ((category === node.slug) ? "has-background-dark" : "")}>
            <figure className="media-left">
                <Link to={"/" + node.slug}>
                    <span className="icon is-medium">
                        <Icon size='fa-2x' />
                    </span>
                </Link>
            </figure>
            <div className="media-content">
                <div className="content">
                    <Link to={"/" + node.slug}>
                        <h1 className="title is-size-4 is-capitalized">{node.slug}</h1>
                    </Link>
                    <MDXRenderer>{node.body}</MDXRenderer>
                </div>
            </div>
        </article>
    )
}

const CategoryList = ({ category, categories }) => {
    const Categories = categories
        .map(({ node }) => {
            const Icon = IconSet[node.frontmatter.icon];
            return <CategoryItem category={category} node={node} />;
        });
    return (
        <>
            {Categories}
        </>
    );
}



export default ({ category }) => {
    return (
        <StaticQuery
            query={graphql`
                query{
                    allMdx(filter: {fields: {source: {eq: "categories"}}}, sort: { order: ASC, fields: [frontmatter___weight] }) {
                        edges {
                            node {
                                id
                                body
                                frontmatter {
                                    title
                                    icon
                                }
                                slug
                            }
                        }
                    }    
                }
            `}
            render={data => <CategoryList category={category} categories={data.allMdx.edges} />}
        />
    );
}
