import React from "react"
import { Link } from 'gatsby';

import Layout from '../components/layout';
import PostLink from "../components/post-link";
import CategoryList from '../components/category-list';


export default ( {pageContext} ) => {
    const {category, edges} = pageContext;
    const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .filter(edge => edge.node.frontmatter.category === category)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout >
        <div className="columns is-multiline">
            <div className="column">
                <article className="content">
                    <Link to="/">
                        <h1 className="title is-4">&lt; Categories</h1>
                    </Link>
                    <div className="is-hidden-mobile">
                        <CategoryList category={category} />
                    </div>
                </article>
            </div>
            <div className="column">
                <h1 className="title is-capitalized is-4">{category}</h1>
                <div>{(Posts.length)?Posts:'There seems to be nothing here... yet'}</div>
            </div>
        </div>
    </Layout>
  )
}